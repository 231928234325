const { IframeButtonDecorator } = require("../../src/services/buttons/IframeButtonDecorator");

window.addEventListener('message', (event) => {
    if (event && event.data && event.data.type == 'parent-ecommerce-data') {
        switch (event.data.event) {
            case 'close':
                closeClsIframe();
                break;
            case 'loaded':
                document.getElementById('iframe-clk').contentWindow.postMessage({ type: 'embed-application-data', hostname: location.origin, price: 0 }, '*');
                break;
            case 'finished':
                break;
        }
    }
});

const getIframeWrapp = () => document.getElementById("clk_Iframe_Id");

const removeIframe = () => {
    if (hasIframe()) {
        getIframeWrapp().remove();
    }
}

const hidesIframe = () => new Promise((resolve, reject) => {
    //Hide the iframe and active the scrolls again. close-ecommerce-iframe
    getIframeWrapp().classList.add('clk_Iframe--hidden');
    setTimeout(() => {
        // hides iframe
        getIframeWrapp().classList.remove('clk_Iframe--visible');
        document.getElementsByTagName('body')[0].style.overflow = "auto";
        document.querySelector('html').style.overflow = "auto";
        resolve(true);
    }, 300);
})


const hasIframe = (eleWrapp) => eleWrapp ? eleWrapp.querySelector('#clk_Iframe_Id') : document.querySelector('#clk_Iframe_Id');

async function OpenClkIframe(clsBtn, iframeOpts) {
    // If theres an invalid param, stop texecuting the method
    if (!clsBtn || !iframeOpts.token) return;
    if (hasIframe()) {
        getIframeWrapp().classList.remove('clk_Iframe--hidden');
        getIframeWrapp().classList.add('clk_Iframe--visible');
        document.getElementsByTagName('body')[0].style.overflow = "hidden";
        document.querySelector('html').style.overflow = "hidden";
        return;
    }
    // creates the iframe ele 
    let buttonToFetch = new IframeButtonDecorator(clsBtn.outerHTML, iframeOpts);
    let btnWithIframe = buttonToFetch.getIframeEle();
    let iframeEle = document.createElement('div');
    iframeEle.innerHTML = btnWithIframe;
    iframeEle = iframeEle.firstElementChild;
    iframeEle.onclick = () => {
        closeClsIframe()
    }
    // append the iframe ele as child of the body
    document.body.appendChild(iframeEle);
    // wait some few miliseconds the iframe ele to be attached 
    setTimeout(() => OpenClkIframe(clsBtn, iframeOpts), 300);
}

async function closeClsIframe() {
    await hidesIframe();
    removeIframe();
}

window.clsJsdkGlobals = {
    OpenClkIframe,
    closeClsIframe,
    hasIframe
}

module.exports = {
    OpenClkIframe, closeClsIframe, hasIframe
}