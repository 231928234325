const { environment } = require("../../configurations/configuration");
const { OpenClkIframe } = require('./cls-iframe-events');

const { gateway } = environment;
// CLK_BUTTON_CREATOR_SCRIPT
// CLK_BUTTON_CREATOR_SCRIPT
let arrDom = document.querySelectorAll("clk-button")

if (!arrDom[0].attributes.withInfoPage) {
    arrDom[0].withInfoPage = 'false'
    arrDom[0].setAttribute('withInfoPage', 'false');
};
const withInfoVal = arrDom[0].attributes.withInfoPage && arrDom[0].attributes.withInfoPage.nodeValue;
arrDom[0].attributes.withInfoPage.nodeValue = withInfoVal !== '' && withInfoVal !== 'true' ? 'false' : 'true';


let arr = [...arrDom].map(({ attributes: a }) => {
    const attrs = {
        description: a.desc.nodeValue,
        button: a.button.nodeValue,
        token: a.token.nodeValue,
        withInfoPage: a.withInfoPage && a.withInfoPage.nodeValue,
        source: a.source?.nodeValue || 'jsdk'
    };
    return a.price ? { ...attrs, price: a.price.nodeValue } : attrs
});

async function createButtons() {
    try {
        // ? {code, msg, object: { buttons: btnArr }}
        //? btnArr = [ {object}, {erros}, {object} ]
        const reqQuery = new URLSearchParams({ iframe: false });
        let e = await fetch(`${gateway}/jsdk/buttons?` + reqQuery, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(arr),
        }).then(t => t.json());
        if (e.code === 200) printButton(e.object);
        else {

        }
    }
    catch (err) {
        console.error("SDK Error: ", err);
    }
}
function printButton(btnsArr) {
    const clkTags = document.querySelectorAll('clk-button');
    const showOnAsstsLoad = (wrappEle) => {
        Promise.all(
            [...wrappEle.querySelectorAll('link')].map(ele => {
                return (new Promise((resolve, reject) => {
                    ele.onload = (e) => resolve(true);
                    ele.onerror = (e) => reject(false);
                }));
            })
        ).then(res => {
            document.querySelectorAll('.cls-button-wrapp').forEach(wrappEle => {
                wrappEle.style = 'display: block;';
                wrappEle.classList.add('cls-button-wrapp--show');
                wrappEle.classList.remove('cls-button-wrapp--hide');
            });
        });
    }
    clkTags.forEach(async (tag, i) => {
        const div = document.createElement('div');
        if (!btnsArr[i].errors && (btnsArr[i].code == 200 || btnsArr[i].code == 201)) {
            div.innerHTML = btnsArr[i].object.btnTag;
            const btnWrapp = div.querySelector('span[class*=cls-button-wrapp]');
            // btnWrapp.style = 'visibility: hidden;';
            btnWrapp.classList.add('cls-button-wrapp--hide');
            tag.parentElement.insertBefore(div, tag);
            // wait for css files to load to show buttons
            showOnAsstsLoad(btnWrapp);
            tag.remove();
            const hasIframe = div.querySelector('#clk_Iframe_Id');
            if (hasIframe) {
                // addIframeEvents();
                document.body.appendChild(hasIframe);
            }
            else {
                // hasIframe && hasIframe.remove();
                const clsBtn = div.querySelector('button[id*=cls-btn]');
                clsBtn.onclick = () => OpenClkIframe(clsBtn.parentElement, arr[i]);
            }
            return;
        }
        console.log("%cSDK Errors: ", "background: red;");
        console.log(btnsArr[i].code);
        btnsArr[i].errors && console.log(btnsArr[i].errors);
    });
}
createButtons();