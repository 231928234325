const { flatHtml } = require("../../utils/textFormatts");
const { environment } = require("../../../configurations/configuration.js");

class IframeButtonDecorator {

    #_wrappedBuilder
    #_opts

    constructor(btnBuilder, opts) {
        this.wrappedBuilder = btnBuilder;
        this.#_opts = opts;
    }

    getIframeEle() {
        let res = '';
        if (this.#_opts.token) {
            let urlIframe = '';
            let urlParams = { source: this.#_opts.source };
            const token = this.#_opts.token;
            if ((typeof this.#_opts.withInfoPage === 'boolean' && this.#_opts.withInfoPage) || this.#_opts.withInfoPage == "true") {
                urlIframe = `benefits/${token}`;

            } else {
                urlParams.token = token;
                urlIframe = `v3/inlineapp/started`;
            }
            if (Object.keys(urlParams).length)
                urlIframe = `${urlIframe}?${Object.entries(urlParams).map(p => p.join('=')).join('&')}`;
            let htmlIframe = `
            <div id="clk_Iframe_Id" class="clk_Iframe clk_Iframe--hidden">
            <link id="cls-iframe-style" rel="stylesheet" href="${environment.gateway}/jsdk/static/css/cls-iframe-style.css">
            <iframe loading="eager" id="iframe-clk" src="${environment.gatewayV3}/${urlIframe}" frameborder="0"></iframe>
            </div>
            `;
            res = htmlIframe;
        }
        return res
    }

    async build() {
        let btnCreated = typeof this.wrappedBuilder === 'string' ? this.wrappedBuilder : flatHtml(await this.wrappedBuilder.build());
        let btnWithIframe = btnCreated.match(/(?<btnContent>.*)(?=<\/span)/);
        let htmlResult = btnCreated;
        if (!btnWithIframe?.groups.btnContent) return htmlResult;
        btnWithIframe = `${btnWithIframe.groups.btnContent} ${this.getIframeEle()} </span>`;
        htmlResult = flatHtml(btnWithIframe);
        return htmlResult;
    }

    get wrappedBuilder() {
        return this.#_wrappedBuilder;
    }

    set wrappedBuilder(newWrappedBuilder) {
        this.#_wrappedBuilder = newWrappedBuilder;
    }


}

module.exports = {
    IframeButtonDecorator
}