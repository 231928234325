function gateWayEvvironment() {
    return 'https://app.clicklease.com';
}

const environment = {
    gateway: 'https://app.clicklease.com',
    gatewayBe: 'https://app.clicklease.com/api',
    gatewayV3: 'https://app.clicklease.com',
}

module.exports = {
    gateWayEvvironment: gateWayEvvironment,
    environment
}