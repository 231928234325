
function getTextPlaceholder(strg, plcHoldName) {
    let result;
    if (plcHoldName) {
        const regxPattern = new RegExp(`\{\{(?<${plcHoldName}>${plcHoldName}|.+?${plcHoldName}|${plcHoldName}.+?|.+?${plcHoldName}.+?)\}\}`);
        result = regxPattern.exec(strg);
    }
    return result;
}

const flatHtml = (htmlStrg) => htmlStrg.replace(/'|"/g, "'").replace(/(\r\n|\n|\r)/gm, "").replace(/\s+/g, ' ');


module.exports = {
    getTextPlaceholder,
    flatHtml
}